<template>
  <div class="footer">
    <div
      v-if="module === 'pipl' && screen === 'jinke'"
      class="pp_text jinke"
    >
      当您点击同意,即表示您已阅读并接受协议及政策内容,您可以阅读完整版
      <InternalLink
        route="/pipl/eula"
        text="《用户协议》"
      /> <InternalLink
        route="/pipl/privacy"
        text="《隐私政策》"
      /> <InternalLink
        route="/pipl/children-privacy"
        text="《儿童隐私保护声明》"
      /> <InternalLink
        :route="storePermission"
        text="《应用权限说明》"
      /> <InternalLink
        route="/pipl/third-share-list"
        text="《第三方共享清单》"
      />
    </div>
    <GlobalAcceptReject
      ref="consentselect"
      :module="module"
      :screen="screen"
    />
    <div
      v-if="module === 'noads'"
      class="pp_text"
    >
      <div>{{ content.noads_linkmore }}</div>
      <ExternalLink
        :url="policyUrl"
        :text="content.noads_pp"
      />
    </div>
  </div>
</template>

<script>
import InternalLink from '@components/InternalLink.vue'
import ExternalLink from '@components/ExternalLink.vue'
import GlobalAcceptReject from '@components/GlobalAcceptReject.vue'

export default {
  name: 'Footer',
  components: {
    GlobalAcceptReject,
    ExternalLink,
    InternalLink
  },
  props: {
    module: {
      type: String,
      default: 'noniab',
    },
    screen: {
      type: String,
      default: 'noniab',
    },
  },
  data() {
    return {
      content: {},
      policyUrl: '',
      store: ''
    }
  },
  computed: {
    storePermission() {
      const s = ['huawei', 'oppo', 'uc', 'qihoo', 'vivo', 'xiaomi', 'honor', 'leyuan233', 'tiktok', 'm4399', 'hykb', 'mkt', 'taptap', 'cps', 'kwai']
      return '/pipl/permission-' + (s.includes(this.store) ? this.store : 'huawei')
    }
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    this.$compliance.GlobalAcceptReject = this.$refs.consentselect
    this.store = this.$compliance.getStore()

    // request default privacy policy url (based on globally set publisher)
    this.$compliance.privacyUrl().then((pp) => {
      this.policyUrl = pp
    })
  },
}
</script>

<style lang="scss">
.footer {
  display: block;
  background: #fff;
  align-items: center;
  padding: 10px 20px 20px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -10px 16px 16px #fff;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 100;
  .span {
    display: inline;
    color: #688194;
    text-decoration: underline;
    flex-basis: 0;
    flex: 1;
    align-self: auto;
    &.hide {
      visibility: hidden;
    }
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }

  .vendors {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0px 10px;
    z-index: 101;
    .span {
      color: #688194;
      text-decoration: underline;
      flex-basis: 0;
      flex: 1;
      align-self: auto;
      &:first-child {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 2.1rem;
      }
    }
  }
  .pp_text {
    position: relative;
    font-size: 0.9em;
    color: #eadacb;
    text-align: center;
    padding: 0 0 10px 0;
    z-index: 1000;
    background: #241720;
    &.jinke {
      color: #aab4bc;
      background: #fff;
    }
    @media screen and (min-width: 460px) {
      font-size: 1em;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.4em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.8em;
    }
  }
}
.absolutefooter .noniabcontainer .footer,
.absolutefooter .lgpdcontainer .footer,
.absolutefooter .tcf20container .footer {
  position: absolute;
  bottom: -20px;
  left: -20px;
  right: -20px;
}
.landscape {
  .footer {
    background: transparent;
    position: absolute;
    -webkit-transform: none;
    transform: none;
    box-shadow: none;
    .vendors {
      box-shadow: 0px -10px 16px 16px #fff;
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      margin: 0 20px;
      .span {
        @media screen and (min-width: 768px) {
          font-size: 1.4rem;
        }
        @media screen and (min-width: 1024px) {
          font-size: 1.8rem;
        }
      }
    }
  }
  &.tablet {
    .footer .vendors .span {
      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 2.1rem;
      }
    }
  }
}
</style>
